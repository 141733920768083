import { LoadingButton } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import {
  BookabilityAttributes,
  BookabilityDecision,
  BookabilityUnmetCriteria,
} from "@src/appV2/OpenShifts/ShiftAction";
import { ShiftSearchMode } from "@src/appV2/OpenShifts/ShiftAction/api/useClaimShift";
import {
  BookabilityNegotiationMetadata,
  ClaimShiftResponse,
} from "@src/appV2/OpenShifts/ShiftAction/types";
import { useIsQuizWorkersOnWorkplaceRulesFeatureEnabled } from "@src/appV2/WorkplaceQuiz/utils/useIsQuizWorkersOnWorkplaceRulesEnabled";
import { Shift } from "@src/lib/interface";

import { BlockedShiftButton } from "./Actions/BlockedShiftButton";
import { BookShiftButton } from "./Actions/BookShiftButton";
import { UndeterminedShiftButton } from "./Actions/UndeterminedShiftButton";
import { useDefinedAgent } from "../store/helperHooks";

export interface ShiftActionButtonProps {
  shift: Shift;
  shiftBookability?: BookabilityAttributes;
  isUrgentShift: boolean;
  isPriorityShift: boolean;
  isShiftBookabilityLoading: boolean;
  searchMode: ShiftSearchMode;
  onBookShift: (shift: ClaimShiftResponse) => void;
  onShiftMarkInterest: () => void;
  negotiationMetadata?: BookabilityNegotiationMetadata;
}

export function ShiftActionButton(props: ShiftActionButtonProps) {
  const {
    shift,
    shiftBookability,
    onBookShift,
    isPriorityShift,
    isUrgentShift,
    isShiftBookabilityLoading,
    searchMode,
    onShiftMarkInterest,
    negotiationMetadata,
  } = props;
  const agent = useDefinedAgent();

  const isQuizWorkersOnWorkplaceRulesFeatureEnabled =
    useIsQuizWorkersOnWorkplaceRulesFeatureEnabled({
      workplace: {
        id: shift.facilityId,
        msa: shift?.facility?.fullAddress?.metropolitanStatisticalArea,
        state: shift?.facility?.fullAddress?.state,
        quizWorkersOnRules: shift.facility?.quizWorkersOnRules,
      },
    });

  if (isShiftBookabilityLoading && !isDefined(shiftBookability)) {
    return <LoadingButton size="small" isLoading={isShiftBookabilityLoading} />;
  }

  if (!isDefined(shiftBookability)) {
    return <UndeterminedShiftButton shiftId={shift._id ?? ""} />;
  }

  /* If shift cannot be booked and is neither due to instant book being disabled
   * nor quiz being being a requirement, show blocked button
   */
  if (
    shiftBookability.decision === BookabilityDecision.BLOCKED &&
    ![
      BookabilityUnmetCriteria.SHIFT_INSTANT_BOOK_DISABLED,
      BookabilityUnmetCriteria.WORKER_HAS_NOT_PASSED_QUIZ_FOR_WORKPLACE_RULES,
    ].includes(shiftBookability.unmetCriteria?.[0])
  ) {
    return <BlockedShiftButton shiftBookability={shiftBookability} shift={shift} />;
  }

  /* If shift can be booked
   * or is blocked due to instant book being disabled show book button
   * or is blocked due to quiz requirement
   */
  if (
    shiftBookability.decision === BookabilityDecision.ALLOWED ||
    shiftBookability.decision === BookabilityDecision.UNDETERMINED ||
    (shiftBookability.decision === BookabilityDecision.BLOCKED &&
      (shiftBookability.unmetCriteria?.[0] ===
        BookabilityUnmetCriteria.SHIFT_INSTANT_BOOK_DISABLED ||
        (shiftBookability.unmetCriteria?.[0] ===
          BookabilityUnmetCriteria.WORKER_HAS_NOT_PASSED_QUIZ_FOR_WORKPLACE_RULES &&
          isQuizWorkersOnWorkplaceRulesFeatureEnabled)))
  ) {
    return (
      <BookShiftButton
        shiftBookability={shiftBookability}
        shift={shift}
        agentId={agent.userId}
        isUrgentShift={isUrgentShift}
        isPriorityShift={isPriorityShift}
        searchMode={searchMode}
        onBookShift={onBookShift}
        onShiftMarkInterest={onShiftMarkInterest}
        negotiationMetadata={negotiationMetadata}
      />
    );
  }

  return <UndeterminedShiftButton shiftId={shift._id ?? ""} shiftBookability={shiftBookability} />;
}
